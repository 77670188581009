<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/2">
					<h6>요청일</h6>
					<div class="flex">
						<b-form-radio class="mr-6" v-model="opt.period" name="searchPeriod" value=""
							>전체기간
						</b-form-radio>
						<b-form-radio v-model="opt.period" name="searchPeriod" value="period" />
						<div class="flex -mt-1.5">
							<datePicker class="w-2/5" model="opt.startDate" />
							<span class="p-2 lh-6">~</span>
							<datePicker class="w-2/5" model="opt.endDate" />
						</div>
					</div>
				</div>
				<div class="w-1/2">
					<h6>파일명</h6>
					<div class="flex">
						<b-form-input
							class="w-1/2"
							v-model.trim="opt.fileName"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="w-1/6 ml-2" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<tb :inf="inf" :res="res" />
	</div>
</template>

<script>
import { downloadExcel } from 'libs/axios'

export default {
	data() {
		const now = new Date(),
			defOpt = {
				startDate: new Date(get_time('d', -7)),
				endDate: now,
				period: 'period',
				fileName: '',
			}

		return {
			opt: { ...defOpt },
			lastOpt: {},
			res: {},
			defOpt,
			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '요청일시', key: 'fileRequestDt', size: 4 },
				{ title: '요청자', key: 'adminName', size: 2 },
				{ title: '요청위치', key: 'position', size: 8, notCenter: 1 },
				{ title: '파일명', key: 'fileName', size: 10, notCenter: 1 },
				{ title: '검색조건', key: 'reqCondition', size: 20, notCenter: 1 },
				{ title: '다운로드 기한', key: 'expirationDt', size: 4 },
				{
					title: '다운로드',
					model: 'button',
					icon: this.getDownloadIcon,
					size: 3,
					func: this.downloadFile,
				},
			],
		}
	},
	methods: {
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('/excelSelect/exportExcelList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					v.fileRequestDt = v.fileRequestDt.replace('T', ' ')
					v.expirationDt = v.expirationDt.replace('T', ' ')
					return v
				})
				this.res = res
			})
		},
		search() {
			let error = [],
				data = {},
				opt = this.opt
			if (opt.period) {
				if (opt.startDate > opt.endDate) error.push('요청 기간을 확인해주세요')
				else {
					data.startDate = opt.startDate.get_time('d', 0, '')
					data.endDate = opt.endDate.get_time('d', 0, '')
				}
			}

			if (opt.fileName) {
				if (opt.fileName.length < 2) error.push('검색어는 2글자 이상 입력해주세요')
				else data.fileName = opt.fileName
			}

			if (error.length) alert.w(error)
			else {
				this.lastOpt = {
					...data,
					adminSeq: layout.user.seqNo,
					orderBy: getOrder('fileRequestDt'),
					paging: getPaging(),
				}
				this.changePage()
			}
		},
		isExpired(item, now) {
			if (!item.expirationDt) return false
			if (!now) now = new Date()
			return new Date(item.expirationDt) < now
		},
		getDownloadIcon(v) {
			if (!v.fileName) return 'three-dots'
			return this.isExpired(v) ? 'x' : 'box-arrow-in-down'
		},
		downloadFile(v) {
			if (v.fileName) {
				if (this.isExpired(v)) alert.w('만료된 파일입니다')
				else downloadExcel(v.exportExcelFileSeq, v.fileName)
			}
		},
	},
	created() {
		this.search()
	},
}
</script>
